import React, { useEffect, useCallback } from 'react';
import Head from 'next/head';
import Link from 'next/link';
import Image from 'next/image';

import Layout, { siteTitle } from '../components/layout';
import utilStyles from '../styles/utils.module.css';
import Date from '../components/date';
import { IStory } from '../types/IStory';
import firebaseService from '../services/firebase';
import { useAppSelector, useAppDispatch } from '../app/hooks';
import {
  update,
  selectStory,
  storyTextAsync,
  storiesAsync,
} from '../components/features/stories/storySlice';

interface StoryProps {
  id: string;
  status: string;
  title: string;
  date: number;
  author: string;
  position: number;
  audioUrl?: string;
  photoCredits?: string;
  illustrator?: string;
  coverThumbUrl?: string;
  coverLargeUrl?: string;
  subTitle?: string;
  rating?: string;
  text?: string;
}

const Story = React.memo(function Story({
  id,
  date,
  title,
  subTitle,
  coverThumbUrl,
  status,
  author,
  position,
  setActiveStory,
}: StoryProps & { setActiveStory: (story: IStory) => void }) {
  const handleClick = useCallback(() => {
    setActiveStory({
      id,
      date,
      title,
      subTitle,
      coverThumbUrl,
      status,
      author,
      position,
    });
  }, [
    id,
    date,
    title,
    subTitle,
    coverThumbUrl,
    status,
    author,
    position,
    setActiveStory,
  ]);

  return (
    <>
      <Link href={`/nyaya/${id}`} onClick={handleClick}>
        {coverThumbUrl && (
          <Image src={coverThumbUrl} alt={title} width={150} height={150} />
        )}
      </Link>
      <div style={{ marginLeft: '16px' }}>
        <Link href={`/nyaya/${id}`} onClick={handleClick}>
          {title}
        </Link>
        <br />
        {subTitle && (
          <div>
            <Link
              href={`/nyaya/${id}`}
              onClick={handleClick}
              className='sub-title'
            >
              {subTitle}
            </Link>
          </div>
        )}
        <small className={utilStyles.dateText}>
          <Date dateEpoch={date} />
        </small>
      </div>
    </>
  );
});

Story.displayName = 'Story';

export default function Home() {
  const { stories } = useAppSelector(selectStory);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!stories.length) {
      dispatch(storiesAsync());
    }

    firebaseService.analytics().logEvent('page_open', {
      page_name: 'Home',
    });
  }, [dispatch, stories.length]);

  const handleAppLink = useCallback(() => {
    firebaseService.analytics().logEvent('playstore_click', {
      page_name: 'Home',
    });
  }, []);

  const setActiveStory = useCallback(
    (story: IStory) => {
      dispatch(storyTextAsync(story.id));
      dispatch(update(story));
    },
    [dispatch]
  );

  return (
    <Layout home>
      <Head>
        <title>{siteTitle}</title>
      </Head>
      <section className={utilStyles.headingSm}>
        <p>African stories as told under a tree in text and audio format.</p>
      </section>

      <section className={`${utilStyles.headingMd} ${utilStyles.padding1px}`}>
        <ul className={utilStyles.list}>
          {stories.length > 1 &&
            stories.map(
              (
                {
                  id,
                  date,
                  title,
                  subTitle,
                  coverThumbUrl,
                  status,
                  author,
                  position,
                }: StoryProps,
                index: number
              ) =>
                index < 40 && (
                  <li className={utilStyles.listItem} key={date}>
                    <div className='detail-image'>
                      <div className='detail-image-pad'>
                        <Story
                          id={id}
                          date={date}
                          title={title}
                          subTitle={subTitle}
                          coverThumbUrl={coverThumbUrl}
                          status={status}
                          author={author}
                          position={position}
                          setActiveStory={setActiveStory}
                        />
                      </div>
                    </div>
                  </li>
                )
            )}
        </ul>
      </section>

      <section className={utilStyles.headingSm}>
        {/* <p>
          <Link
            href={
              'https://play.google.com/store/apps/details?id=com.bagggu.bushveld'
            }
            target='_blank'
            onClick={handleAppLink}
          >
            <Image
              src='https://bushveld.s3.eu-west-2.amazonaws.com/android.svg'
              alt='Download Bushveld Android app'
              width={150}
              height={150}
            />
          </Link>
        </p> */}
      </section>
    </Layout>
  );
}
