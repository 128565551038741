import React from 'react';
import Head from 'next/head';
import Image from 'next/image';
import styles from './layout.module.css';
import utilStyles from '../styles/utils.module.css';
import Link from 'next/link';

const name = 'Bushveld';
export const siteTitle = 'Bushveld';

export default function Layout({
  children,
  home,
}: {
  children: React.ReactNode;
  home?: boolean;
}) {
  return (
    <div className={styles.container}>
      <Head>
        <link
          rel='icon'
          href='https://bushveld.s3.eu-west-2.amazonaws.com/icon.png'
        />
        <meta
          name='description'
          content='African stories as told under a tree in text and audio format.'
        />
        <meta
          property='og:image'
          content={'https://thebushveld.com/images/cover.webp'}
        />
        <meta name='og:title' content={siteTitle} />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='google-site-verification'
          content='9YxxqoTKC7ihRjqaV0FvaaS3tPmodOv2zJuSpB054hA'
        />
        <link rel='preconnect' href='https://fonts.gstatic.com'></link>
        {/* <link
          href="https://fonts.googleapis.com/css2?family=Eczar:wght@400;500&display=swap"
          rel="stylesheet"
        ></link> */}
      </Head>
      <header className={styles.header}>
        {home ? (
          <Image
            src='/images/logo.svg'
            alt='Bushveld logo'
            width={200}
            height={50}
            className={`${styles.headerHomeImage} ${utilStyles.borderCircle}`}
          />
        ) : (
          <>
            <Link href='/'>
              <Image
                src='/images/logo.svg'
                alt='Bushveld logo'
                width={180}
                height={50}
                className={`${styles.headerImage} ${utilStyles.borderCircle}`}
              />
            </Link>
          </>
        )}
      </header>
      <main>{children}</main>
      {!home && (
        <div className={styles.backToHome}>
          <Link href='/'>← Back to home</Link>
        </div>
      )}
    </div>
  );
}
